import { LOADING } from 'redux/action-types';

const initialState = {
  isLoading: false
};
const loadingActionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        isLoading: payload
      };
    default:
      return state;
  }
};
export default loadingActionReducer;
