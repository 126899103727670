import { lazy } from 'react';
import {
  FACILITY_VIEW_TIMESHEET,
  PROVIDER_SIGNUP,
  PROVIDER_VERIFY_EMAIL,
  PROVIDER_VERIFY_TOKEN,
  RECOVER_PASSWORD,
  RESET_PASSWORD
} from 'helpers/page-urls';
const Signup = lazy(() => import('pages/auth/signup'));
const VerifyToken = lazy(() => import('pages/auth/signup/VerifyToken'));
const EmailVerification = lazy(() => import('pages/auth/signup/VerifyEmail'));
const FacilityViewTimesheet = lazy(() =>
  import('pages/facility-view-timesheet/FacilityViewTimesheet')
);
const RecoverPassword = lazy(() => import('pages/auth/forgot-password/RecoverPassword'));
const ResetPassword = lazy(() => import('pages/auth/forgot-password/ResetPassword'));

const ROUTES = [
  {
    routeName: 'Signup',
    path: PROVIDER_SIGNUP,
    component: Signup
  },
  {
    routeName: 'Email Verification',
    path: PROVIDER_VERIFY_EMAIL,
    component: EmailVerification
  },
  {
    routeName: 'Verify Token',
    path: PROVIDER_VERIFY_TOKEN,
    component: VerifyToken
  },
  {
    routeName: 'Facility View Timesheet',
    path: FACILITY_VIEW_TIMESHEET,
    component: FacilityViewTimesheet
  },
  {
    routeName: 'Recover Password',
    path: RECOVER_PASSWORD,
    component: RecoverPassword
  },
  {
    routeName: 'Reset Password',
    path: RESET_PASSWORD,
    component: ResetPassword
  }
];

export default ROUTES;
