import { lazy } from 'react';
import {
  PROVIDER_PROFILE,
  ADMIN_GET_PROVIDER_PROFILE,
  PROVIDER_EXPENSES_LISTING,
  PROVIDER_ADD_EXPENSES,
  PROVIDER_VIEW_EXPENSES,
  PROVIDER_EDIT_EXPENSES,
  PROVIDER_TIMESHEET_LISTING,
  PROVIDER_ADD_TIMESHEET,
  PROVIDER_ADD_DAILYTIMESHEET,
  PROVIDER_VIEW_TIMESHEET,
  PROVIDER_VIEW_BASIC_INFO,
  PROVIDER_LISTING_ASSIGNED_FACILITIES,
  PROVIDER_VIEW_ASSIGNED_FACILITIES,
  PROVIDER_EDIT_TIMESHEET,
  PROVIDER_UPDATE_PASSWORD
} from 'helpers/page-urls';

// const RolesListing = lazy(() => import('pages/admin/roles/RolesListing'));
const Profile = lazy(() => import('pages/profile'));
const ExpensesListing = lazy(() => import('pages/expenses/ExpensesListing'));
const AddExpenses = lazy(() => import('pages/expenses/AddExpenses'));
const ViewExpenses = lazy(() => import('pages/expenses/ViewExpenses'));
const TimesheetListing = lazy(() => import('pages/timesheet/TimesheetListing'));
const AddTimesheet = lazy(() => import('pages/timesheet/provider/AddTimesheet'));
const AddDailyTimesheet = lazy(() => import('pages/timesheet/provider/AddDailyTimesheet'));
const ViewTimesheet = lazy(() => import('pages/timesheet/ViewTimesheet'));
const ViewBasicInfo = lazy(() => import('pages/admin/users/providers/view-providers/BasicInfo'));
const ListAssignedFacilities = lazy(() =>
  import('pages/admin/users/providers/view-providers/facilities')
);
const ViewAssignedFacilities = lazy(() =>
  import('pages/admin/users/providers/view-providers/facilities/ViewFacility')
);
const UpdatePassword = lazy(() => import('pages/auth/forgot-password/UpdatePassword'));

const ROUTES = [
  {
    routeName: 'Profile',
    path: PROVIDER_PROFILE,
    component: Profile
  },
  {
    routeName: 'Admin Get Provider Profile',
    path: ADMIN_GET_PROVIDER_PROFILE,
    component: Profile
  },
  {
    routeName: 'Expenses Listing',
    path: PROVIDER_EXPENSES_LISTING,
    component: ExpensesListing,
    layout: 'admin'
  },
  {
    routeName: 'Add Expense',
    path: PROVIDER_ADD_EXPENSES,
    component: AddExpenses,
    layout: 'admin'
  },
  {
    routeName: 'Edit Expenses',
    path: PROVIDER_EDIT_EXPENSES,
    component: AddExpenses,
    layout: 'admin'
  },
  {
    routeName: 'View Expenses',
    path: PROVIDER_VIEW_EXPENSES,
    component: ViewExpenses,
    layout: 'admin'
  },
  {
    routeName: 'Provider Timesheet Listing',
    path: PROVIDER_TIMESHEET_LISTING,
    component: TimesheetListing,
    layout: 'admin'
  },
  {
    routeName: 'Provider Add Timesheet',
    path: PROVIDER_ADD_TIMESHEET,
    component: AddTimesheet,
    layout: 'admin'
  },
  {
    routeName: 'Provider Add Daily Timesheet',
    path: PROVIDER_ADD_DAILYTIMESHEET,
    component: AddDailyTimesheet,
    layout: 'admin'
  },
  {
    routeName: 'Provider Edit Timesheet',
    path: PROVIDER_EDIT_TIMESHEET,
    component: AddTimesheet,
    layout: 'admin'
  },
  {
    routeName: 'Provider View Timesheet',
    path: PROVIDER_VIEW_TIMESHEET,
    component: ViewTimesheet,
    layout: 'admin'
  },
  {
    routeName: 'Provider View Basic Info',
    path: PROVIDER_VIEW_BASIC_INFO,
    component: ViewBasicInfo,
    layout: 'admin'
  },
  {
    routeName: 'Provider Listing Assigned Facilities',
    path: PROVIDER_LISTING_ASSIGNED_FACILITIES,
    component: ListAssignedFacilities,
    layout: 'admin'
  },
  {
    routeName: 'Provider View Assigned Facilities',
    path: PROVIDER_VIEW_ASSIGNED_FACILITIES,
    component: ViewAssignedFacilities,
    layout: 'admin'
  },
  {
    routeName: 'Provider Update Password',
    path: PROVIDER_UPDATE_PASSWORD,
    component: UpdatePassword,
    layout: 'admin'
  }
];

export default ROUTES;
