import { axiosClient } from 'services/interfaces/axios-client';

/**
 *
 * @param {*} URL endpoint for API
 * @param {*} config Optional: Additional request headers if any by default will be empty, those set by axiosClient
 */
export function getRequest(URL, config = {}) {
  return axiosClient.get(`/${URL}`, config).then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function putRequest(URL, payload) {
  return axiosClient.put(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`).then((response) => response);
}
