import React from 'react';
import Footer from 'components/common/Footer';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import { Outlet } from 'react-router-dom';
import { PROVIDER_ADD_TIMESHEET } from 'helpers/page-urls';
import { currentPathURL } from 'helpers/utility-functions';

const DashboardWrapper = () => {
  const handleSidebar = () => {
    document?.body?.classList?.add('nav-open');
  };

  // Getting current page URL
  const currentPageURL = window.location.pathname;
  // Getting current page without id
  const currentPageURLWithoutID = currentPathURL(window.location.pathname);

  return (
    <>
      <Header handleSidebar={handleSidebar} />
      <Sidebar />
      <section className="maindashboard">
        <main
          className={`contentwrapper ${
            currentPageURL === PROVIDER_ADD_TIMESHEET ||
            currentPageURLWithoutID === '/provider-view-timesheet/'
              ? 'px-3'
              : ''
          }`}>
          <Outlet />
        </main>
        <Footer />
      </section>
    </>
  );
};

export default DashboardWrapper;
