import React from 'react';
import { NavLink } from 'react-router-dom';
import { setSubMenuSelected } from 'redux/actions/sidebar-menu/sidebarMenu';
import { useDispatch, useSelector } from 'react-redux';
import { searchFilter } from 'redux/reducers/search-filters/searchFilters';

const SubMenuItem = (props) => {
  const dispatch = useDispatch();
  const { submenuItems, removeNavClass } = props;
  const { submenuSelected } = useSelector((state) => state.sidebarMenu);

  return (
    <>
      {submenuItems?.map((submenuItem, index) => (
        <div
          key={index}
          className={`submenu  ${submenuSelected === submenuItem.value ? 'active' : ''}`}>
          <li
            onClick={(e) => {
              dispatch(searchFilter(''));
              e.preventDefault();

              dispatch(setSubMenuSelected(submenuItem.value));
              removeNavClass();
            }}
            className="relative">
            <NavLink
              data-testid={`submenu-${submenuItem?.id}`}
              to={submenuItem?.url}
              className="cursor-pointer fs-14">
              <span className={`${submenuItem.icon}`}></span>
              <span className="">{submenuItem.title}</span>
            </NavLink>
          </li>
        </div>
      ))}
    </>
  );
};

export default SubMenuItem;
