import React from 'react';
import { ROLES_CONSTANT, STATUS_CONSTANT } from 'helpers/constants';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRouteAdmin = ({ isLoggedIn, role, status }) => {
  return isLoggedIn &&
    (role === ROLES_CONSTANT?.superAdmin ||
      (role === ROLES_CONSTANT?.iconUser && status === STATUS_CONSTANT?.confirmed)) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRouteAdmin;
