import React from 'react';
import pageNotFound from 'assets/images/404.svg';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <section className="pageNotFound d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-7 col-md-12">
            <img src={pageNotFound} alt="pageNotFound" className="img-fluid" />
            <div className="cta404 text-center">
              <h1 className="fs-52 mb-3">PAGE NOT FOUND</h1>
              <Link to="/" className="btn btn-primary btn-md">
                Go Home
              </Link>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-9 col-md-12">
                <p className="mt-32 text-center">
                  <span className="fw-bold">Page Not Found: </span>
                  The page that you are trying to access is not available. Please reach out to ICON
                  administrator if you have any queries
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
