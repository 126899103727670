import React, { lazy, Suspense, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/screen.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AdminRoutes from 'routes/AdminRoutes';
import PrivateRouteAdmin from 'routes/PrivateRouteAdmin';
import PrivateRouteProvider from 'routes/PrivateRouteProvider';
import ProviderRoutes from 'routes/ProviderRoutes';
import PublicRoutes from 'routes/PublicRoutes';

import DashboardWrapper from 'components/layouts/DashboardWrapper';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'components/common/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
const Login = lazy(() => import('pages/auth/login/Login'));
import { setLoading } from 'redux/actions/loading/loading';
import PageNotFound from 'components/common/PageNotFound';
// import { getUserPermissions } from 'helpers/utility-functions';
import { getPermissions } from 'redux/actions/permissions/permissions';
import { ROLES_CONSTANT, STATUS_CONSTANT } from 'helpers/constants';
import { userPermissions } from 'services/api/admin/roles';

const App = () => {
  const dispatch = useDispatch();
  // Sometimes API call gets in pending state or it takes too much time for getting response
  // So if user refresh the page in loading state then the loader should get false from the redux store
  useEffect(() => {
    window.sessionStorage.removeItem('searchDetails');
    dispatch(setLoading(false));
  }, []);

  const getActiveUserPermissions = async (role) => {
    const permissions = await userPermissions(role);
    dispatch(getPermissions(permissions));
  };

  // this useEffect is for when admin update user permissions and user refresh the page and get updated permissions
  useEffect(() => {
    if (
      user?.role &&
      user?.role === ROLES_CONSTANT?.iconUser &&
      user?.profile?.status === STATUS_CONSTANT?.confirmed
    ) {
      getActiveUserPermissions(user?.role);
    }
  }, [window.location.pathname]);

  const { isLoggedIn, user } = useSelector((state) => state?.auth);
  const { isLoading } = useSelector((state) => state?.loading);
  return (
    <>
      <BrowserRouter>
        <Loader loading={isLoading} />
        <ToastContainer autoClose={10000} transition={Zoom} />
        <Suspense fallback={<Loader loading={true} />}>
          <Routes>
            <Route element={<Navigate to="login" />} path="/" />
            <Route element={<Login />} path="/login" />

            <Route
              element={
                <PrivateRouteAdmin
                  isLoggedIn={isLoggedIn}
                  role={user?.role}
                  status={user?.profile?.status}
                />
              }>
              {AdminRoutes.map((route, index) => {
                // Check if route name is Profile then doesn't add dashboard wrapper (sidebar menus)
                if (route?.routeName === 'Profile') {
                  return <Route key={index} path={route.path} element={<route.component />} />;
                } else {
                  return (
                    <Route key={index} element={<DashboardWrapper />}>
                      <Route path={route.path} element={<route.component />} />;
                    </Route>
                  );
                }
              })}
            </Route>

            <Route element={<PrivateRouteProvider isLoggedIn={isLoggedIn} user={user} />}>
              {ProviderRoutes.map((route, index) => {
                // Check if layout is admin then add dashboard wrapper (sidebar menus)
                if (route?.layout === 'admin') {
                  return (
                    <Route key={index} element={<DashboardWrapper />}>
                      <Route path={route.path} element={<route.component />} />;
                    </Route>
                  );
                } else {
                  return <Route key={index} path={route.path} element={<route.component />} />;
                }
              })}
            </Route>

            <Route>
              {PublicRoutes.map((route, index) => {
                return <Route key={index} path={route.path} element={<route.component />} />;
              })}

              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
