import {
  PROVIDER_EXPENSES_LISTING,
  PROVIDER_TIMESHEET_LISTING,
  PROVIDER_VIEW_BASIC_INFO,
  PROVIDER_LISTING_ASSIGNED_FACILITIES,
  PROVIDER_UPDATE_PASSWORD,
  PROVIDER_ADD_TIMESHEET
} from './page-urls';

export const menuItemsProvider = [
  {
    title: 'Time Sheets',
    id: 'time-sheets',
    url: PROVIDER_TIMESHEET_LISTING,
    urlsub: PROVIDER_ADD_TIMESHEET,
    icon: 'icon icon-TimeSheets',
    value: 1
  },
  {
    title: 'Expenses',
    id: 'expenses',
    url: PROVIDER_EXPENSES_LISTING,
    icon: 'icon icon-Expense-Management light-weight',
    value: 2
  },
  {
    title: 'Profile Settings',
    id: 'profile-settings',
    url: PROVIDER_VIEW_BASIC_INFO,
    urlsub: PROVIDER_LISTING_ASSIGNED_FACILITIES,
    urlsub1: PROVIDER_UPDATE_PASSWORD,
    icon: 'icon icon-ProfileSettings light-weight',
    value: 3
  }
];
