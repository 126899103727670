export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const LOADING = 'LOADING';
export const MENU_SELECTED = 'MENU_SELECTED';
export const SUB_MENU_SELECTED = 'SUB_MENU_SELECTED';
export const OPEN_SUB_MENU = 'OPEN_SUB_MENU';
export const DEFAULT_MENU = 'DEFAULT_MENU';
export const SET_SCOPES = 'SET-SCOPES';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const EMPTY_PERMISSIONS = 'EMPTY_PERMISSIONS';
