const AUTH = {
  login_success: 'Login successful',
  logout_success: 'Logout successful',
  reset_password_success: 'Password has been reset successfully',
  update_password_success: 'Password has been updated successfully'
};

const PROVIDERS = {
  delete_success: 'Provider has been deleted successfully',
  update_profile_sussess: 'Profile has been updated successfully',
  postalCodeRequired: 'Country and postal code is required for address 2'
};

const FACILITY_ASSIGNMENTS = {
  add_success: 'Provider facility assignment has been added successfully',
  update_success: 'Provider facility assignment has been updated successfully'
};

const PROVIDER_RATES = {
  add_success: 'Pay rate has been added successfully',
  update_success: 'Pay rate has been updated successfully',
  delete_success: 'Pay rate has been removed successfully',
  approved_success: 'Pay rate has been approved successfully'
};

const MALPRACTICE_RATES = {
  add_success: 'Malpractice rate has been added successfully',
  update_success: 'Malpractice rate has been updated successfully',
  delete_success: 'Malpractice has been deleted successfully',
  state_rate_update_success: 'Malpractice state rate has been updated successfully',
  state_rate_delete_success: 'Malpractice state rate has been deleted successfully'
};

const FACILITIES = {
  add_success: 'Facility has been added successfully',
  update_success: 'Facility has been updated successfully',
  delete_success: 'Facility has been deleted successfully'
};

const ICON_USERS = {
  add_success: 'ICON user has been created successfully',
  update_success: 'ICON user has been updated successfully',
  delete_success: 'ICON user has been deleted successfully'
};

const ROLES = {
  add_success: 'Role has been created successfully',
  update_success: 'Role has been updated successfully',
  delete_success: 'Role has been deleted successfully'
};

const EXPENSES = {
  add_success: 'Expense has been added successfully',
  update_success: 'Expense has been updated successfully',
  delete_success: 'Expense has been deleted successfully',
  approved_success: 'Expense has been approved successfully'
};

const BONUSES = {
  add_success: 'Bonus has been added successfully',
  update_success: 'Bonus has been updated successfully',
  delete_success: 'Bonus has been deleted successfully',
  approved_success: 'Bonus has been approved successfully'
};

const TIMESHEETS = {
  add_success: 'Time log has been added successfully',
  submit_for_approval_success: 'Timesheet has been submitted for approval successfully',
  delete_success: 'Timesheet has been deleted successfully',
  approved_success: 'Timesheet has been approved successfully',
  export_download_error: 'Something went wrong, please try again',
  timesheet_rejection: 'Timesheet rejected successfully'
};

const NOTIFICATIONS = {
  reminder_success: 'Reminder has been sent successfully'
};

const PAYMENTS = {
  recalculate_success: 'Payment has been recalculated successfully'
};

const COMMON = {
  export_download_error: 'Something went wrong, please try again',
  permission_error: 'You don`t have access to this page'
};

export {
  AUTH,
  PROVIDERS,
  FACILITY_ASSIGNMENTS,
  PROVIDER_RATES,
  MALPRACTICE_RATES,
  FACILITIES,
  ICON_USERS,
  ROLES,
  EXPENSES,
  BONUSES,
  TIMESHEETS,
  NOTIFICATIONS,
  PAYMENTS,
  COMMON
};
