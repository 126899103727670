import { SET_SCOPES, GET_PERMISSIONS } from 'redux/action-types';

export const setScopes = (scopes) => (dispatch) => {
  dispatch({
    type: SET_SCOPES,
    payload: scopes
  });
};

export const getPermissions = (permissions) => (dispatch) => {
  dispatch({
    type: GET_PERMISSIONS,
    payload: permissions
  });
};
