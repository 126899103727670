const LOGIN = '/login';
const RECOVER_PASSWORD = '/recover-password';
const RESET_PASSWORD = '/reset-password/:token';

const FACILITY_VIEW_TIMESHEET = '/facility-view-timesheet';

const ADMIN_PROVIDERS_LISTING = '/providers-listing';
// const ADMIN_CREATE_PROVIDERS = '/create-providers';
const ADMIN_VIEW_PROVIDERS = '/view-providers/:id';
const ADMIN_VIEW_DOCUMENTS = '/view-documents/:id';
const ADMIN_VIEW_CREDENTIALING = '/view-credentialing/:id';
const ADMIN_VIEW_PROFILE_HISTORY = '/view-profile-history/:id';
const ADMIN_VIEW_PROVIDER_FACILITIES = '/view-provider-facilities/:id';
const ADMIN_VIEW_PROVIDER_ASSIGN_FACILITY = '/view-provider-assign-facility/:id';
const ADMIN_VIEW_PROVIDER_EDIT_FACILITY = '/view-provider-edit-facility/:id';
const ADMIN_VIEW_PROVIDER_VIEW_FACILITY = '/view-provider-view-facility/:id';
const ADMIN_GET_PROVIDER_PROFILE = '/profile/:id';

const ADMIN_FACILITIES_LISTING = '/facilities-listing';
const ADMIN_ADD_FACILITIES = '/add-facilities';
const ADMIN_EDIT_FACILITIES = '/edit-facilities/:id';
const ADMIN_VIEW_FACILITIES = '/view-facilities/:id';

const ADMIN_ICON_USERS_LISTING = '/icon-users-listing';
const ADMIN_CREATE_ICON_USERS = '/create-icon-users';
const ADMIN_EDIT_ICON_USERS = '/edit-icon-users/:id';

const ADMIN_ROLES_LISTING = '/roles-listing';
const ADMIN_CREATE_ROLES = '/create-roles';
const ADMIN_EDIT_ROLES = '/edit-roles/:id';

const ADMIN_TIMESHEET_LISTING = '/admin-timesheet-listing';
const ADMIN_ADD_TIMESHEET = '/admin-add-timesheet';
const ADMIN_EDIT_TIMESHEET = '/admin-edit-timesheet/:timesheetId';
const ADMIN_VIEW_TIMESHEET = '/admin-view-timesheet/:timesheetId';
const ADMIN_VIEW_TIMESHEET_URL = '/admin-view-timesheet';
const ADMIN_ADD_TIMESHEET_IMPORT = '/admin-add-timesheet-import';

const PROVIDER_TIMESHEET_LISTING = '/provider-timesheet-listing';
const PROVIDER_ADD_TIMESHEET = '/provider-add-timesheet';
const PROVIDER_ADD_DAILYTIMESHEET = '/provider-add-dailytimesheet';
const PROVIDER_EDIT_TIMESHEET = '/provider-edit-timesheet/:timesheetId';
const PROVIDER_VIEW_TIMESHEET = '/provider-view-timesheet/:timesheetId';
const PROVIDER_VIEW_TIMESHEET_URL = '/provider-view-timesheet';

const ADMIN_PAYRATES_LISITING = '/payrates-listing';
const ADMIN_CREATE_PAYRATES = '/add-payrates';
const ADMIN_VIEW_PAYRATES = '/view-payrates/:payrateid';
const ADMIN_EDIT_PAYRATES = '/edit-payrates/:payrateid';
const ADMIN_ADD_PAYRATES_IMPORT = '/add-payrates-import';

const ADMIN_MALPRACTICE_LISITING = '/malpractice-listing';
const ADMIN_ADD_MALPRACTICERATES = '/add-malpracticerates';
const ADMIN_ADD_MALPRACTICERATES_IMPORT = '/add-malpracticerates-import';

const ADMIN_EXPENSES_LISTING = '/admin-expenses-listing';
const ADMIN_ADD_EXPENSES = '/admin-add-expenses';
const ADMIN_VIEW_EXPENSES = '/admin-view-expenses/:expenseId';
const ADMIN_EDIT_EXPENSES = '/admin-edit-expenses/:expenseId';
const ADMIN_UPDATE_PASSWORD = '/update-password';

const ADMIN_BONUS_LISITING = '/bonus-listing';
const ADMIN_ADD_BONUS = '/add-bonus';
const ADMIN_VIEW_BONUS = '/view-bonus/:bonusId';
const ADMIN_EDIT_BONUS = '/edit-bonus/:bonusId';

// admin payments
const ADMIN_PAYMENTS_LISTING = '/payments-listing';
const ADMIN_VIEW_PAYMENT = '/view-payment/:paymentId';

const PROVIDER_SIGNUP = '/signup';
const PROVIDER_PROFILE = '/profile';
const PROVIDER_VERIFY_EMAIL = '/verify-email';
const PROVIDER_VERIFY_TOKEN = '/verify/:token/:email';

const PROVIDER_EXPENSES_LISTING = '/provider-expenses-listing';
const PROVIDER_ADD_EXPENSES = '/provider-add-expenses';
const PROVIDER_VIEW_EXPENSES = '/provider-view-expenses/:expenseId';
const PROVIDER_EDIT_EXPENSES = '/provider-edit-expenses/:expenseId';
const PROVIDER_UPDATE_PASSWORD = '/provider-update-password';

const PROVIDER_VIEW_BASIC_INFO = '/provider-view-basic-info';
const PROVIDER_LISTING_ASSIGNED_FACILITIES = '/provider-listing-assignedfacilities';
const PROVIDER_VIEW_ASSIGNED_FACILITIES = '/provider-view-assignedfacilities';

//admin notifications
const ADMIN_NOTIFICATIONS_LISTING = '/notifications-listing';

// admin pay periods
const ADMIN_PAY_PERIODS_LISTING = '/pay-periods-listing';

export {
  LOGIN,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  FACILITY_VIEW_TIMESHEET,
  ADMIN_PROVIDERS_LISTING,
  ADMIN_VIEW_PROVIDERS,
  ADMIN_VIEW_DOCUMENTS,
  ADMIN_VIEW_CREDENTIALING,
  ADMIN_VIEW_PROFILE_HISTORY,
  ADMIN_VIEW_PROVIDER_FACILITIES,
  ADMIN_VIEW_PROVIDER_ASSIGN_FACILITY,
  ADMIN_VIEW_PROVIDER_EDIT_FACILITY,
  ADMIN_VIEW_PROVIDER_VIEW_FACILITY,
  ADMIN_GET_PROVIDER_PROFILE,
  ADMIN_FACILITIES_LISTING,
  ADMIN_ADD_FACILITIES,
  ADMIN_EDIT_FACILITIES,
  ADMIN_VIEW_FACILITIES,
  ADMIN_ICON_USERS_LISTING,
  ADMIN_CREATE_ICON_USERS,
  ADMIN_ROLES_LISTING,
  ADMIN_CREATE_ROLES,
  ADMIN_EDIT_ROLES,
  ADMIN_TIMESHEET_LISTING,
  ADMIN_ADD_TIMESHEET,
  ADMIN_EDIT_TIMESHEET,
  ADMIN_VIEW_TIMESHEET,
  ADMIN_VIEW_TIMESHEET_URL,
  ADMIN_EDIT_ICON_USERS,
  ADMIN_PAYRATES_LISITING,
  ADMIN_CREATE_PAYRATES,
  ADMIN_VIEW_PAYRATES,
  ADMIN_EDIT_PAYRATES,
  ADMIN_ADD_PAYRATES_IMPORT,
  ADMIN_MALPRACTICE_LISITING,
  ADMIN_ADD_MALPRACTICERATES,
  ADMIN_ADD_MALPRACTICERATES_IMPORT,
  ADMIN_EXPENSES_LISTING,
  ADMIN_ADD_EXPENSES,
  ADMIN_VIEW_EXPENSES,
  ADMIN_EDIT_EXPENSES,
  ADMIN_UPDATE_PASSWORD,
  ADMIN_BONUS_LISITING,
  ADMIN_ADD_BONUS,
  ADMIN_VIEW_BONUS,
  ADMIN_EDIT_BONUS,
  ADMIN_PAYMENTS_LISTING,
  ADMIN_VIEW_PAYMENT,
  PROVIDER_SIGNUP,
  PROVIDER_PROFILE,
  PROVIDER_VERIFY_EMAIL,
  PROVIDER_VERIFY_TOKEN,
  PROVIDER_EXPENSES_LISTING,
  PROVIDER_ADD_EXPENSES,
  PROVIDER_VIEW_EXPENSES,
  PROVIDER_EDIT_EXPENSES,
  PROVIDER_UPDATE_PASSWORD,
  PROVIDER_VIEW_BASIC_INFO,
  PROVIDER_LISTING_ASSIGNED_FACILITIES,
  PROVIDER_VIEW_ASSIGNED_FACILITIES,
  PROVIDER_TIMESHEET_LISTING,
  PROVIDER_ADD_TIMESHEET,
  PROVIDER_ADD_DAILYTIMESHEET,
  PROVIDER_EDIT_TIMESHEET,
  PROVIDER_VIEW_TIMESHEET,
  PROVIDER_VIEW_TIMESHEET_URL,
  ADMIN_NOTIFICATIONS_LISTING,
  ADMIN_PAY_PERIODS_LISTING,
  ADMIN_ADD_TIMESHEET_IMPORT
};
