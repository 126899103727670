import React from 'react';
import { Dropdown } from 'react-bootstrap';
import useUserData from 'hooks/useUserData';
import { returnFullName } from 'helpers/utility-functions';
import { useDispatch } from 'react-redux';
import { ADMIN_UPDATE_PASSWORD, PROVIDER_UPDATE_PASSWORD } from 'helpers/page-urls';
import { clearAllFilters } from 'redux/reducers/search-filters/searchFilters';
import { logoutStatic } from 'redux/actions/auth/auth';
import Avatar from 'assets/images/userpic-placeholder.svg';

const Header = ({ handleSidebar }) => {
  const dispatch = useDispatch();
  const { roleProvider, roleIconuser, user, signedURL } = useUserData();

  return (
    <header>
      <nav className="mainheader">
        <div className="ml-auto">
          <div className="loggedin-user p5 d-flex align-items-center justify-content-between">
            <button
              id="openNav"
              data-testid="openNav"
              onClick={handleSidebar}
              className="hamburgers ms-2">
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div className="d-flex align-items-center justify-content-end flex-grow-1 mr-10">
              <Dropdown align="end" className="table-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <div className="d-flex align-items-center justify-content-end flex-grow-1">
                    <div className="user-pic">
                      <img
                        width={40}
                        src={roleProvider ? signedURL ?? Avatar : Avatar}
                        alt="user pic"
                      />
                    </div>

                    <span className="d-none d-md-block fw-700">
                      {roleProvider
                        ? returnFullName(user?.profile?.firstName, user?.profile?.lastName)
                        : roleIconuser
                        ? returnFullName(user?.profile?.firstName, user?.profile?.lastName)
                        : 'Admin'}
                    </span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    data-testid="reset-password-btn"
                    href={roleProvider ? PROVIDER_UPDATE_PASSWORD : ADMIN_UPDATE_PASSWORD}>
                    Update Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-testid="logoutbtn"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(clearAllFilters({}));
                      dispatch(logoutStatic());
                      // toast.success(AUTH?.logout_success);
                    }}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
