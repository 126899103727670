import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import { Provider } from 'react-redux';
import configureStores from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { SetupInterceptors } from 'services/interfaces/axios-client';

const store = configureStores().store;
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={configureStores().persistor}>
      <App />
    </PersistGate>
  </Provider>
);

//Pass store to this function because store is not accessible without functional component
SetupInterceptors(store);
