import React from 'react';
import logo from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/" className="brand-link">
      <img src={logo} alt="logo" />
    </Link>
  );
};

export default Logo;
