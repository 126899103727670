import { GET_PROFILE_SUCCESS, GET_PROFILE_FAIL, LOGOUT } from 'redux/action-types';

const initialState = { profile: null, signedURL: null };

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload?.profile,
        signedURL: payload?.signedURL
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        profile: null,
        signedURL: null
      };
    case LOGOUT:
      return {
        ...state,
        profile: null,
        signedURL: null
      };
    default:
      return state;
  }
};
export default profileReducer;
