import React from 'react';
import { NavLink } from 'react-router-dom';
import SubMenuItem from './SubMenuItem';
import {
  setMenuSelected,
  setSubMenuSelected,
  setOpenSubMenu
} from 'redux/actions/sidebar-menu/sidebarMenu';
import { useDispatch, useSelector } from 'react-redux';
import { searchFilter } from 'redux/reducers/search-filters/searchFilters';

const MenuItem = (props) => {
  const { menuItem, handleSubMenu } = props;
  const { openSubMenu, menuSelected } = useSelector((state) => state?.sidebarMenu);

  const removeNavClass = () => {
    document?.querySelector('body')?.classList?.remove('nav-open');
  };

  const dispatch = useDispatch();
  return (
    <>
      <div
        className={` 
                  ${menuSelected === menuItem.value ? 'active' : ''} mr-2`}>
        <li
          onClick={(e) => {
            dispatch(searchFilter(''));
            e.preventDefault();

            if (menuItem.submenu) {
              if (openSubMenu === '') {
                dispatch(setMenuSelected(-1));
                return;
              }
            }
            dispatch(setMenuSelected(menuItem.value));
            dispatch(setSubMenuSelected(menuItem.value));
            dispatch(setOpenSubMenu(menuItem.title));
            removeNavClass();
          }}>
          <NavLink
            data-testid={`mainmenu-${menuItem?.id}`}
            to={menuItem.url}
            className="cursor-pointer position-relative fs-14">
            <span className={`${menuItem.icon}`}></span>
            <span className="ml-4">{menuItem.title}</span>
            {/* Showing main menus of submenu  */}
            {menuItem.submenu && (
              <>
                <span
                  className="float-end ml-6 fulllink"
                  onClick={() => {
                    dispatch(searchFilter(''));
                    if (menuItem.submenu) {
                      if (openSubMenu === '') {
                        dispatch(setMenuSelected(-1));

                        handleSubMenu(menuItem.title);
                        dispatch(setSubMenuSelected(1));
                      } else {
                        handleSubMenu('');
                        dispatch(setSubMenuSelected(0));
                      }
                    } else {
                      handleSubMenu('');
                      dispatch(setSubMenuSelected(0));
                    }
                  }}>
                  {openSubMenu === menuItem.title ? (
                    <span className="expand icon-Chevron-Up"></span>
                  ) : (
                    <span className="expand icon-Chevron-Down"></span>
                  )}
                </span>
              </>
            )}
          </NavLink>
        </li>
      </div>
      {/* Showing sub menus  */}
      {openSubMenu === menuItem.title && (
        <SubMenuItem submenuItems={menuItem.submenu} removeNavClass={removeNavClass} />
      )}
    </>
  );
};

export default MenuItem;
