import { postRequest } from 'services/interfaces/axios-methods';
import {
  LOGIN_API_URL,
  FORGOT_PASSWORD_API_URL,
  RESET_PASSWORD_API_URL
} from 'services/interfaces/api-urls';

async function loginUser(values) {
  try {
    const response = await postRequest(LOGIN_API_URL, {
      email: values.email,
      password: values.password
    });
    return await response;
  } catch (error) {
    return await error;
  }
}

async function forgotPassword(values) {
  try {
    const response = await postRequest(FORGOT_PASSWORD_API_URL, values);
    return response?.data;
  } catch (error) {
    return await error;
  }
}

async function resetPassword(values) {
  try {
    const response = await postRequest(RESET_PASSWORD_API_URL, values);
    return response?.data;
  } catch (error) {
    return await error;
  }
}

export { loginUser, forgotPassword, resetPassword };
