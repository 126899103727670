import { STATUS_CONSTANT } from 'helpers/constants';
import { ROLES_CONSTANT } from 'helpers/constants';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_PROFILE_FAIL,
  DEFAULT_MENU,
  EMPTY_PERMISSIONS
} from 'redux/action-types';
import { loginUser } from 'services/api/common/auth';

export const login = (data) => (dispatch) => {
  return loginUser(data).then(
    (result) => {
      if (result?.data) {
        //Failed to login if provider not signed the agreement
        if (
          result?.data?.data?.role === ROLES_CONSTANT?.provider &&
          result?.data?.data?.profile?.agreementStatus === STATUS_CONSTANT?.notSigned
        ) {
          dispatch({
            type: LOGIN_FAIL
          });
        }
        //success login for both provider and super-admin
        else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              user: result?.data?.data,
              profile: result?.data?.data?.profile,
              userPermissions: result?.data?.data?.profile?.roles
                ? result?.data?.data?.profile?.roles[0]?.permissions?.map(
                    (permission) => permission.value
                  )
                : null
            }
          });
        }
      } else {
        dispatch({
          type: LOGIN_FAIL
        });
      }
      return Promise.resolve(result);
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL
      });

      return Promise.reject(error);
    }
  );
};

export const logoutStatic = () => (dispatch) => {
  dispatch({
    type: GET_PROFILE_FAIL
  });
  dispatch({
    type: DEFAULT_MENU
  });

  dispatch({
    type: LOGOUT
  });

  dispatch({
    type: EMPTY_PERMISSIONS
  });
};
