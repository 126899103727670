import { lazy } from 'react';

//React lazy loading for good application performance
const ProvidersListing = lazy(() => import('pages/admin/users/providers/ProvidersListing'));
const FacilitiesListing = lazy(() => import('pages/admin/users/facilities/FacilitiesListing'));
const CreateRoles = lazy(() => import('pages/admin/roles/CreateRoles'));
const RolesListing = lazy(() => import('pages/admin/roles/RolesListing'));
const IconUsersListing = lazy(() => import('pages/admin/users/icon-users/IconUsersListing'));
const CreateIconUsers = lazy(() => import('pages/admin/users/icon-users/CreateIconUsers'));
const AddFacilities = lazy(() => import('pages/admin/users/facilities/AddFacilities'));
const BasicInfo = lazy(() => import('pages/admin/users/providers/view-providers/BasicInfo'));
const Documents = lazy(() => import('pages/admin/users/providers/view-providers/documents'));
const CredentialingHistory = lazy(() =>
  import('pages/admin/users/providers/view-providers/CredentialingHistory')
);
const ProfileHistory = lazy(() =>
  import('pages/admin/users/providers/view-providers/ProfileHistory')
);
const Facilities = lazy(() => import('pages/admin/users/providers/view-providers/facilities'));
const AssignFacility = lazy(() =>
  import('pages/admin/users/providers/view-providers/facilities/AssignFacility')
);
const ViewFacility = lazy(() =>
  import('pages/admin/users/providers/view-providers/facilities/ViewFacility')
);
const ViewFacilities = lazy(() => import('pages/admin/users/facilities/ViewFacilities'));
const PayratesLisiting = lazy(() => import('pages/admin/settings/payrates/PayratesListing'));
const AddPayratesManual = lazy(() => import('pages/admin/settings/payrates/AddPayratesManual'));
const ViewPayrates = lazy(() => import('pages/admin/settings/payrates/ViewPayrates'));
const AddPayratesImport = lazy(() => import('pages/admin/settings/payrates/AddPayratesImport'));
const MalpracticeListing = lazy(() =>
  import('pages/admin/settings/malpractice/MalpracticeListing')
);
const AddMalpracticeRatesManual = lazy(() =>
  import('pages/admin/settings/malpractice/AddMalpracticeRatesManual')
);
const AddMalpracticeRatesImport = lazy(() =>
  import('pages/admin/settings/malpractice/AddMalpracticeRatesImport')
);
const ExpensesListing = lazy(() => import('pages/expenses/ExpensesListing'));
const AddExpenses = lazy(() => import('pages/expenses/AddExpenses'));
const ViewExpenses = lazy(() => import('pages/expenses/ViewExpenses'));
const BonusListing = lazy(() => import('pages/admin/bonus/BonusListing'));
const AddBonus = lazy(() => import('pages/admin/bonus/AddBonus'));
const ViewBonus = lazy(() => import('pages/admin/bonus/ViewBonus'));

const Profile = lazy(() => import('pages/profile'));

// notifications pages
const NotificationsListing = lazy(() =>
  import('pages/admin/settings/notifications/NotificationsListing')
);
// timesheet
const TimesheetListing = lazy(() => import('pages/timesheet/TimesheetListing'));
const AddTimesheet = lazy(() => import('pages/timesheet/admin/AddTimesheet'));
const ViewTimesheet = lazy(() => import('pages/timesheet/ViewTimesheet'));
const AddTimesheetImport = lazy(() => import('pages/timesheet/admin/AddTimesheetImport'));
// Payments
const PaymentsListing = lazy(() => import('pages/admin/payments/PaymentsListing'));
// payment
const ViewPayment = lazy(() => import('pages/admin/payments/ViewPayment'));
// update passwrd
const UpdatePassword = lazy(() => import('pages/auth/forgot-password/UpdatePassword'));
// Pay Periods
const PayPeriodsListing = lazy(() => import('pages/admin/settings/pay-periods/PayPeriodsListing'));

import {
  ADMIN_ADD_FACILITIES,
  ADMIN_CREATE_ICON_USERS,
  ADMIN_CREATE_ROLES,
  ADMIN_EDIT_FACILITIES,
  ADMIN_EDIT_ICON_USERS,
  ADMIN_EDIT_ROLES,
  ADMIN_FACILITIES_LISTING,
  ADMIN_ICON_USERS_LISTING,
  ADMIN_PROVIDERS_LISTING,
  ADMIN_ROLES_LISTING,
  ADMIN_VIEW_DOCUMENTS,
  ADMIN_VIEW_CREDENTIALING,
  ADMIN_VIEW_PROFILE_HISTORY,
  ADMIN_VIEW_PROVIDER_FACILITIES,
  ADMIN_VIEW_FACILITIES,
  ADMIN_VIEW_PROVIDERS,
  ADMIN_PAYRATES_LISITING,
  ADMIN_CREATE_PAYRATES,
  ADMIN_VIEW_PAYRATES,
  ADMIN_EDIT_PAYRATES,
  ADMIN_ADD_PAYRATES_IMPORT,
  ADMIN_VIEW_PROVIDER_ASSIGN_FACILITY,
  ADMIN_VIEW_PROVIDER_EDIT_FACILITY,
  ADMIN_VIEW_PROVIDER_VIEW_FACILITY,
  ADMIN_MALPRACTICE_LISITING,
  ADMIN_ADD_MALPRACTICERATES,
  ADMIN_ADD_MALPRACTICERATES_IMPORT,
  ADMIN_GET_PROVIDER_PROFILE,
  ADMIN_EXPENSES_LISTING,
  ADMIN_ADD_EXPENSES,
  ADMIN_BONUS_LISITING,
  ADMIN_ADD_BONUS,
  ADMIN_VIEW_BONUS,
  ADMIN_EDIT_BONUS,
  ADMIN_VIEW_EXPENSES,
  ADMIN_EDIT_EXPENSES,
  ADMIN_NOTIFICATIONS_LISTING,
  ADMIN_TIMESHEET_LISTING,
  ADMIN_ADD_TIMESHEET,
  ADMIN_VIEW_TIMESHEET,
  ADMIN_PAYMENTS_LISTING,
  ADMIN_VIEW_PAYMENT,
  ADMIN_ADD_TIMESHEET_IMPORT,
  ADMIN_UPDATE_PASSWORD,
  ADMIN_PAY_PERIODS_LISTING
} from 'helpers/page-urls';

const ROUTES = [
  {
    routeName: 'Providers Listing',
    path: ADMIN_PROVIDERS_LISTING,
    component: ProvidersListing
  },
  {
    routeName: 'View Provider Profile',
    path: ADMIN_VIEW_PROVIDERS,
    component: BasicInfo
  },
  {
    routeName: 'View Provider Documents',
    path: ADMIN_VIEW_DOCUMENTS,
    component: Documents
  },
  {
    routeName: 'View Provider Credentialing',
    path: ADMIN_VIEW_CREDENTIALING,
    component: CredentialingHistory
  },
  {
    routeName: 'View Provider Profile History',
    path: ADMIN_VIEW_PROFILE_HISTORY,
    component: ProfileHistory
  },
  {
    routeName: 'View Provider Facilities',
    path: ADMIN_VIEW_PROVIDER_FACILITIES,
    component: Facilities
  },
  {
    routeName: 'View Provider Assign Facility',
    path: ADMIN_VIEW_PROVIDER_ASSIGN_FACILITY,
    component: AssignFacility
  },
  {
    routeName: 'View Provider View Facility',
    path: ADMIN_VIEW_PROVIDER_VIEW_FACILITY,
    component: ViewFacility
  },
  {
    routeName: 'View Facility',
    path: ADMIN_VIEW_FACILITIES,
    component: ViewFacilities
  },
  {
    routeName: 'Edit Facility',
    path: ADMIN_VIEW_PROVIDER_EDIT_FACILITY,
    component: AssignFacility
  },
  {
    routeName: 'Facilities Listing',
    path: ADMIN_FACILITIES_LISTING,
    component: FacilitiesListing
  },
  {
    routeName: 'Add Facility',
    path: ADMIN_ADD_FACILITIES,
    component: AddFacilities
  },
  {
    routeName: 'Edit Facility',
    path: ADMIN_EDIT_FACILITIES,
    component: AddFacilities
  },
  {
    routeName: 'Create Roles',
    path: ADMIN_CREATE_ROLES,
    component: CreateRoles
  },
  {
    routeName: 'Edit Roles',
    path: ADMIN_EDIT_ROLES,
    component: CreateRoles
  },
  {
    routeName: 'Roles Listing',
    path: ADMIN_ROLES_LISTING,
    component: RolesListing
  },
  {
    routeName: 'Admin Timesheet Listing',
    path: ADMIN_TIMESHEET_LISTING,
    component: TimesheetListing
  },
  {
    routeName: 'Admin Timesheet Add',
    path: ADMIN_ADD_TIMESHEET,
    component: AddTimesheet
  },
  {
    routeName: 'Admin Timesheet View',
    path: ADMIN_VIEW_TIMESHEET,
    component: ViewTimesheet
  },
  {
    routeName: 'Add Timesheet Import',
    path: ADMIN_ADD_TIMESHEET_IMPORT,
    component: AddTimesheetImport
  },
  {
    routeName: 'ICON Users Listing',
    path: ADMIN_ICON_USERS_LISTING,
    component: IconUsersListing
  },
  {
    routeName: 'Create ICON Users',
    path: ADMIN_CREATE_ICON_USERS,
    component: CreateIconUsers
  },
  {
    routeName: 'Edit ICON Users',
    path: ADMIN_EDIT_ICON_USERS,
    component: CreateIconUsers
  },
  {
    routeName: 'Payrates Lisiting',
    path: ADMIN_PAYRATES_LISITING,
    component: PayratesLisiting
  },
  {
    routeName: 'Add Payrates',
    path: ADMIN_CREATE_PAYRATES,
    component: AddPayratesManual
  },
  {
    routeName: 'View Payrates',
    path: ADMIN_VIEW_PAYRATES,
    component: ViewPayrates
  },
  {
    routeName: 'Edit Payrates',
    path: ADMIN_EDIT_PAYRATES,
    component: AddPayratesManual
  },
  {
    routeName: 'Add Payrates Import',
    path: ADMIN_ADD_PAYRATES_IMPORT,
    component: AddPayratesImport
  },
  {
    routeName: 'Malpractice Lisiting',
    path: ADMIN_MALPRACTICE_LISITING,
    component: MalpracticeListing
  },
  {
    routeName: 'Add Malpractice',
    path: ADMIN_ADD_MALPRACTICERATES,
    component: AddMalpracticeRatesManual
  },
  {
    routeName: 'Add Malpractice Import',
    path: ADMIN_ADD_MALPRACTICERATES_IMPORT,
    component: AddMalpracticeRatesImport
  },
  {
    routeName: 'Expenses Lisiting',
    path: ADMIN_EXPENSES_LISTING,
    component: ExpensesListing
  },
  {
    routeName: 'Add Expenses',
    path: ADMIN_ADD_EXPENSES,
    component: AddExpenses
  },
  {
    routeName: 'Edit Expenses',
    path: ADMIN_EDIT_EXPENSES,
    component: AddExpenses
  },
  {
    routeName: 'View Expenses',
    path: ADMIN_VIEW_EXPENSES,
    component: ViewExpenses
  },
  {
    routeName: 'Bonus & Incentive Lisiting',
    path: ADMIN_BONUS_LISITING,
    component: BonusListing
  },
  {
    routeName: 'Add Bonus',
    path: ADMIN_ADD_BONUS,
    component: AddBonus
  },
  {
    routeName: 'View Bonus',
    path: ADMIN_VIEW_BONUS,
    component: ViewBonus
  },
  {
    routeName: 'Edit Bonus',
    path: ADMIN_EDIT_BONUS,
    component: AddBonus
  },
  {
    routeName: 'Profile',
    path: ADMIN_GET_PROVIDER_PROFILE,
    component: Profile
  },
  {
    routeName: 'Notifications Listing',
    path: ADMIN_NOTIFICATIONS_LISTING,
    component: NotificationsListing
  },
  {
    routeName: 'Payments Listing',
    path: ADMIN_PAYMENTS_LISTING,
    component: PaymentsListing
  },
  {
    routeName: 'View Payment',
    path: ADMIN_VIEW_PAYMENT,
    component: ViewPayment
  },
  {
    routeName: 'Update Password',
    path: ADMIN_UPDATE_PASSWORD,
    component: UpdatePassword
  },
  {
    routeName: 'Pay Periods Listing',
    path: ADMIN_PAY_PERIODS_LISTING,
    component: PayPeriodsListing
  }
];

export default ROUTES;
