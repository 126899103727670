import _ from 'lodash';
import { provider, superAdmin, iconUser } from 'services/interfaces/api-urls';
import { STATUS_CONSTANT, callTypes } from './constants';
import { rolePermissions } from 'services/api/admin/roles';

const replaceString = (value, patterns, replace, toUpperCase = false) => {
  const replaceValue = _.replace(value, new RegExp(patterns, 'g'), replace);

  if (toUpperCase) {
    // convert the first word to upper case if toUpperCase is passed || Hello World ... => HELLO World ...
    const words = replaceValue.split(' ');
    words[0] = words[0].toUpperCase();
    return words.join(' ');
  }
  return replaceValue;
};

const paginationCount = (totalCount, limit) => {
  return Math.ceil(totalCount / limit);
};

//create sorting object for table sorting serverside
const createSortingObject = (sortvalue) => {
  return {
    sortBy: sortvalue[0]?.id,
    sortDirection: sortvalue[0]?.id ? (sortvalue[0]?.desc ? 1 : -1) : ''
  };
};

//endPoint object that returns endpoints of provider and super-admin
const endPoints = {
  provider: provider,
  'super-admin': superAdmin,
  'icon-user': iconUser,
  default: provider
};

const getEndpoint = (role) => endPoints[role] || endPoints.default;

// Return different colors according to profile status
const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case STATUS_CONSTANT.completed:
    case STATUS_CONSTANT.signed:
    case STATUS_CONSTANT.approved:
      return 'clr-green';
    case STATUS_CONSTANT.incomplete:
    case STATUS_CONSTANT.pending:
    case STATUS_CONSTANT.approvalPending:
      return 'clr-orange';
    case STATUS_CONSTANT.notSigned:
    case STATUS_CONSTANT.notApplied:
    case STATUS_CONSTANT.notApproved:
    case STATUS_CONSTANT.notSubmitted:
    case STATUS_CONSTANT.rejected:
      return 'clr-red';
    default:
      return '';
  }
};

//Return full name
const returnFullName = (firstName, lastName) => {
  if (firstName && lastName) {
    return firstName + ' ' + lastName;
  }

  return '-';
};

const currentPathURL = (url) => {
  const lastIndex = url?.lastIndexOf('/');
  const before = url?.slice(0, lastIndex);

  return before + '/';
};

//Truncates a number to two decimal places.
const truncateToTwoDecimalPlaces = (number) => {
  return Math.trunc(number * 100) / 100;
};

const transformArrayToObject = (arr) => {
  // Use Array.reduce() to transform the array into a single object with the desired format
  const transformed = arr?.reduce((acc, { permissions }) => {
    permissions.forEach(({ value }) => {
      const key = value.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
      acc[key] = value;
    });
    return acc;
  }, {});

  // Return the transformed object
  return transformed;
};

// get all permissions
const getPermissionsScopes = async (role) => {
  let scopes = {};
  const response = await rolePermissions(role);
  if (response) {
    scopes = transformArrayToObject(response);
  }
  return scopes;
};

// Remove empty strings with null
const removeEmptyStringsWithNull = (obj) => {
  const newObj = {};
  for (const prop in obj) {
    newObj[prop] = obj[prop] === '' ? null : obj[prop];
  }
  return newObj;
};

// filterDataById method returns object from array that matches with provided ID
const filterDataById = (data, id) => {
  return data.filter((obj) => obj.value === id);
};

// Convert camelcase fields to add space and convert to uppercase
function formatFieldName(fieldName) {
  // Convert camelCase to space-separated words
  var spacedWords = fieldName.replace(/([A-Z])/g, ' $1');

  // Capitalize the first letter of each word
  var formattedFieldName = spacedWords.charAt(0).toUpperCase() + spacedWords.slice(1);

  return formattedFieldName;
}

// return pay rates for payments based on holidays and callType
const returnPayRate = ({ holidayRates, regularRates }, isHoliday, key, callType) => {
  let payRates = {};
  // first choose which rates to pick from
  // if isHoliday pick holiday rates else pick regular rates
  if (isHoliday) {
    payRates = holidayRates;
  } else {
    payRates = regularRates;
  }

  // if call type is passed, pick call type pay rate
  if (callType) {
    if (callType === callTypes?.eveningOnCallPager) {
      return payRates?.['eveningOnCallPayRate'] || 0;
    } else if (callType === callTypes?.TwentyFourHourCallPager) {
      return payRates?.['twentyFourHourOnCallPayRate'] || 0;
    } else if (callType === callTypes?.inHouseCall) {
      return payRates?.['inHouseOnCallPayRate'] || 0;
    }
  }

  // pick hourly rates
  if (key) {
    return `${payRates?.[key] || 0}`;
  }

  return '-';
};
//Generate Years
const generateYears = () => {
  const startYear = 2023;
  const endYear = 2100;
  const yearArr = new Array(endYear - startYear + 1);

  let currentYear = startYear;
  for (let i = 0; i < yearArr.length; i++) {
    yearArr[i] = { label: currentYear, value: currentYear };
    currentYear++;
  }

  return yearArr;
};

// This function calculates the starting index of items on a specific page.
// It takes the 'limit' (number of items per page) and 'offset' (current page number) as parameters.
const getPageStart = (limit, offset) => {
  return limit * offset;
};

// This function generates the user-friendly label indicating the range of items being shown.
// It takes the 'total' number of items, 'limit' (number of items per page), and 'offset' (current page number) as parameters.
const getPageLabel = (total, limit, offset) => {
  // Calculate the starting index of items for the current page.
  const start = Math.max(getPageStart(limit, offset - 1), 0);

  // Calculate the ending index of items for the current page.
  // Ensure that the ending index doesn't exceed the total number of items.
  const end = Math.min(getPageStart(limit, offset), total);

  // Generate and return a user-friendly label indicating the range of items being shown.
  // Format: "Showing start+1 to end of total"
  return `Showing ${start + 1} to ${end} of ${total} entries`;
};

const cleanUpMocks = () => {
  beforeEach(() => {
    jest.clearAllMocks(); // This will clear all mocks before each test
  });
};

const createMockStore = (state) => {
  const getState = () => state;
  const dispatch = jest.fn();

  return {
    getState,
    dispatch,
    subscribe: jest.fn()
  };
};

/**
 * Truncate number without rounding off
 * @param {Number} number number to truncate
 * @param {Number} fixed numbers to show after decimal points: 2 by default
 * @returns
 */
const truncateWithoutRounding = (n, fixed = 2) => {
  if (!n) {
    return 0;
  }
  return +`${n}`.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed}})?`))[0];
};

export {
  replaceString,
  paginationCount,
  getEndpoint,
  getStatusColor,
  returnFullName,
  createSortingObject,
  currentPathURL,
  truncateToTwoDecimalPlaces,
  transformArrayToObject,
  getPermissionsScopes,
  removeEmptyStringsWithNull,
  filterDataById,
  formatFieldName,
  returnPayRate,
  generateYears,
  getPageLabel,
  cleanUpMocks,
  createMockStore,
  truncateWithoutRounding
};
