import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filtervalue: '',
  filterObj: {}
};

const searchFilterSlice = createSlice({
  name: 'searchfilters',
  initialState: initialState,
  reducers: {
    searchFilter: (state, action) => {
      state.filtervalue = action.payload;
    },
    // this action will add filter of new key that were passed and remains filter of other keys as it is
    setSearchFilter: (state, action) => {
      state.filterObj[action.payload.key] = {
        ...state.filterObj[action.payload.key],
        ...action.payload.values
      };
    },
    // for resetting the specific filter (with pagination and sorting) on the basis of key
    clearFilter: (state, action) => {
      state.filterObj[action.payload] = {
        ...state.filterObj[action.payload],
        filterValue: '',
        page: 1,
        offset: 1,
        sortValue: [],
        isSorted: false
      };
    },
    // for resetting the filter (not pagination and sorting only Search fields)
    clearFilterOnly: (state, action) => {
      state.filterObj[action.payload] = {
        ...state.filterObj[action.payload],
        filterValue: ''
      };
    },
    // for resetting the whole filter obj on logout
    clearAllFilters: (state, action) => {
      state.filterObj = action.payload;
    }
  }
});

export const { searchFilter, setSearchFilter, clearFilter, clearFilterOnly, clearAllFilters } =
  searchFilterSlice.actions;
export default searchFilterSlice.reducer;
