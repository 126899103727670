import axios from 'axios';
import { toast, Zoom } from 'react-toastify';
import { logoutStatic } from 'redux/actions/auth/auth';
import { setLoading } from 'redux/actions/loading/loading';
import { COMMON } from './messages';

var axiosClient = axios.create({});
axiosClient.defaults.baseURL =
  // "https://api-iconexchnage.troonlab.io/api";
  process.env.REACT_APP_BASEURL;

//To avoid duplicate error message
const notify = (message, toastId) => {
  toast.error(message, {
    autoClose: 10000,
    transition: Zoom,
    delay: 999,
    toastId
  });
};
const SetupInterceptors = (store) => {
  const errorHandler = (error) => {
    // console.log(error?.response?.data?.error?.message);
    switch (error?.status || error.response.status) {
      case 200:
        if (error?.response?.status === 400) {
          toast.error(error?.data?.message);
        }
        break;
      case 400:
        if (error?.response?.data?.error?.message) {
          toast.error(error?.response?.data?.error?.message);
        } else {
          toast.error(error?.response?.data?.error);
        }

        break;
      case 401:
        notify(error?.response?.data?.error, error.toString());
        break;
      case 403:
        // show common error message if the response is arrayBuffer type
        toast.error(error?.response?.data?.error || COMMON.permission_error);
        break;
      case 404:
        //show an error message incase content is missing
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          //show an error message incase api not found
          toast.error(error?.response?.statusText);
        }
        break;
      case 415:
        toast.error(error?.response?.statusText);
        break;
      case 500:
        toast.error('Internal Server Error');
        break;
    }
  };

  // Add a request interceptor
  axiosClient.interceptors.request.use(
    (config) => {
      store.dispatch(setLoading(true));

      const jwt = store.getState()?.auth?.user?.token;

      config.headers.Authorization = jwt ? `Bearer ${jwt}` : '';

      return config;
    },
    (error) => {
      store.dispatch(setLoading(false));
      return error;
    }
  );
  axiosClient.interceptors.response.use(
    (response) => {
      store.dispatch(setLoading(false));
      errorHandler(response);
      return response;
    },
    (error) => {
      store.dispatch(setLoading(false));
      if (error.response.status === 401) {
        notify(error?.response?.data?.error, error.toString());
        store.dispatch(logoutStatic());
        // window.location.reload();
      } else {
        errorHandler(error);
      }
      return Promise.reject(error);
    }
  );
};

export { SetupInterceptors, axiosClient };
