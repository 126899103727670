const statusYesNo = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

//Expenses type static list
const expenseType = [
  {
    label: 'Lodging',
    value: 'lodging'
  },
  {
    label: 'Parking',
    value: 'parking'
  },
  {
    label: 'Gas',
    value: 'gas'
  },
  {
    label: 'Air Fare',
    value: 'air-fare'
  },
  {
    label: 'Mileage',
    value: 'mileage'
  },
  {
    label: 'Car Rental',
    value: 'car-rental'
  },
  {
    label: 'Stipend',
    value: 'stipend'
  },
  {
    label: 'Tolls',
    value: 'tolls'
  },
  {
    label: 'Meals',
    value: 'meals'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

//profile pic upload validations
const fileSize = 15000000; //15mb
const supportedFormats = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

//Roles constant used throughout the app
const ROLES_CONSTANT = {
  superAdmin: 'super-admin',
  iconUser: 'icon-user',
  provider: 'provider'
};

//Status constant used throughout the app
const STATUS_CONSTANT = {
  pending: 'pending',
  submitted: 'submitted',
  notSubmitted: 'not-submitted',
  rejected: 'rejected',
  completed: 'completed',
  incomplete: 'incomplete',
  confirmed: 'confirmed',
  signed: 'signed',
  notSigned: 'not-signed',
  approved: 'approved',
  notApplied: 'not-applied',
  notApproved: 'not-approved',
  approvalPending: 'approval-pending'
};

//Select dorpdown lists
const statusApprovedPendingNotApproved = [
  {
    label: 'Approved',
    value: STATUS_CONSTANT?.approved
  },
  {
    label: 'Pending',
    value: STATUS_CONSTANT?.pending
  },
  {
    label: 'Not Approved',
    value: STATUS_CONSTANT?.notApproved
  }
];

//Select dorpdown lists
const timesheetStatuses = [
  {
    label: 'Approved',
    value: STATUS_CONSTANT?.approved
  },
  {
    label: 'Approval Pending',
    value: STATUS_CONSTANT?.approvalPending
  },
  {
    label: 'Rejected',
    value: STATUS_CONSTANT?.rejected
  },
  {
    label: 'Not Submitted',
    value: STATUS_CONSTANT?.notSubmitted
  }
];

//Select dorpdown lists
const statusApprovedPendingNotApplied = [
  {
    label: 'Approved',
    value: STATUS_CONSTANT?.approved
  },
  {
    label: 'Pending',
    value: STATUS_CONSTANT?.pending
  },
  {
    label: 'Not Applied',
    value: STATUS_CONSTANT?.notApplied
  }
];

//Select dorpdown lists
const onlyApprovedNotApprovedStatus = [
  {
    label: 'Approved',
    value: STATUS_CONSTANT?.approved
  },
  {
    label: 'Not Approved',
    value: STATUS_CONSTANT?.notApproved
  }
];

//Select dorpdown lists
const statusCompleteIncomplete = [
  {
    label: 'Completed',
    value: STATUS_CONSTANT.completed
  },
  {
    label: 'Incomplete',
    value: STATUS_CONSTANT.incomplete
  }
];

//Select dorpdown lists
const statusSignedNotSigned = [
  {
    label: 'Signed',
    value: STATUS_CONSTANT.signed
  },
  {
    label: 'Not Signed',
    value: STATUS_CONSTANT.notSigned
  }
];

//Expenses type static list
const bonusTypes = {
  hoursCompletionBonus: 'Hours Completion Bonus',
  performanceBonus: 'Performance Bonus',
  referralBonus: 'Referral Bonus',
  incentive: 'Incentive',
  others: 'Others'
};

const staticNames = {
  CRNA: 'Certified Registered Nurse Anesthetist (CRNA)',
  iconAnesthesia: 'Icon Anesthesia',
  daily: 'daily',
  biWeekly: 'bi_weekly',
  iconExchange: 'Icon Exchange',
  nonIconExchange: 'Non Icon Exchange'
};

//Timesheet logtype select options
const logTypeSelectOptions = [
  {
    label: 'Bi-Weekly',
    value: staticNames?.biWeekly
  },
  {
    label: 'Daily',
    value: staticNames?.daily
  }
];

//Statuses for timesheet
const TIMESHEET_APPROVAL_STATUS = {
  approved: 'approved',
  pending: 'approval-pending',
  not_submitted: 'not-submitted',
  rejected: 'rejected'
};

// For setting dynamic filter, pagination and sorting from redux
const filterKeys = {
  provider: 'provider',
  assignedFacilities: 'assignedFacilities',
  payRates: 'payRates',
  malpracticeInfoRates: 'malpracticeInfoRates',
  malpracticeStateRates: 'malpracticeStateRates',
  facility: 'facility',
  iconUser: 'iconUser',
  roles: 'roles',
  expense: 'expense',
  bonus: 'bonus',
  timesheet: 'timesheet',
  payments: 'payments',
  notifications: 'notifications',
  payperiods: 'payperiods'
};

//Timesheet call types
const callTypes = {
  eveningOnCallPager: 'evening-on-call-pager',
  TwentyFourHourCallPager: '24-hour-pager-call',
  inHouseCall: 'in-house-call'
};

//Companies list for icon
const companies = {
  iconExchange: 'icon-exchange',
  iconAnesthesia: 'icon-anesthesia'
};

//Managing records per page from env variables for different envs
const paginationPerPage = process.env.REACT_APP_PAGINATION_PERPAGE;

//Signature font static
const signatureFont = 'Reenie Beanie, cursive';
const weekDays = 7;

export {
  statusApprovedPendingNotApproved,
  statusApprovedPendingNotApplied,
  fileSize,
  supportedFormats,
  ROLES_CONSTANT,
  STATUS_CONSTANT,
  expenseType,
  onlyApprovedNotApprovedStatus,
  statusCompleteIncomplete,
  statusSignedNotSigned,
  statusYesNo,
  bonusTypes,
  staticNames,
  logTypeSelectOptions,
  TIMESHEET_APPROVAL_STATUS,
  timesheetStatuses,
  filterKeys,
  callTypes,
  companies,
  paginationPerPage,
  signatureFont,
  weekDays
};
