import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MenuItem from 'components/ui/menu/MenuItem';
import useUserData from 'hooks/useUserData';
import { menuItemsAdmin } from 'helpers/admin-menu-constants';
import { menuItemsProvider } from 'helpers/provider-menu-constants';
import { currentPathURL } from 'helpers/utility-functions';
import {
  setOpenSubMenu,
  setMenuSelected,
  setSubMenuSelected
} from 'redux/actions/sidebar-menu/sidebarMenu';
import { ROLES_CONSTANT } from 'helpers/constants';
import Logo from './Logo';
import _ from 'lodash';
import {
  ADMIN_ICON_USERS_LISTING,
  ADMIN_PAY_PERIODS_LISTING,
  ADMIN_PROVIDERS_LISTING,
  PROVIDER_TIMESHEET_LISTING,
  PROVIDER_VIEW_BASIC_INFO
} from 'helpers/page-urls';

const Sidebar = () => {
  const { role } = useUserData();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location || {};

  const handleSubMenu = async (submenu) => {
    // For applying sub menu selection class
    dispatch(setOpenSubMenu(submenu));
  };

  // Check if role is super admin or ICON user then show admin menus
  const menuItems =
    role === ROLES_CONSTANT?.superAdmin || role === ROLES_CONSTANT?.iconUser
      ? menuItemsAdmin
      : menuItemsProvider;

  useEffect(() => {
    menuStates();
  }, []);

  useEffect(() => {
    if (pathname === ADMIN_PROVIDERS_LISTING) {
      dispatch(setOpenSubMenu('Providers'));
      dispatch(setMenuSelected(1));
      dispatch(setSubMenuSelected(1));
    } else if (role !== ROLES_CONSTANT?.superAdmin && pathname === PROVIDER_TIMESHEET_LISTING) {
      dispatch(setMenuSelected(1));
    } else if (role !== ROLES_CONSTANT?.superAdmin && pathname === PROVIDER_VIEW_BASIC_INFO) {
      dispatch(setMenuSelected(3));
    } else if (
      (role === ROLES_CONSTANT?.superAdmin || role === ROLES_CONSTANT?.iconUser) &&
      pathname === ADMIN_ICON_USERS_LISTING
    ) {
      dispatch(setOpenSubMenu('ICON Users'));
      dispatch(setSubMenuSelected(7));
    } else if (role === ROLES_CONSTANT?.superAdmin && pathname === ADMIN_PAY_PERIODS_LISTING) {
      dispatch(setOpenSubMenu('Settings'));
      dispatch(setSubMenuSelected(1));
    }
    //eslint-disable-next-line
  }, []);

  const menuStates = () => {
    //Mapping mainmenu arrays
    _.map(menuItems, (menuItems) => {
      //Mapping submenu arrays
      _.map(menuItems?.submenu, (submenu) => {
        //Getting current url and matching with static data and applying active class
        if (
          window.location.pathname === submenu?.url ||
          window.location.pathname === submenu?.urlsub ||
          window.location.pathname === submenu?.urlsub1 ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub1) &&
            currentPathURL(window.location.pathname) !== '/') ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub2) &&
            currentPathURL(window.location.pathname) !== '/') ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub3) &&
            currentPathURL(window.location.pathname) !== '/') ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub4) &&
            currentPathURL(window.location.pathname) !== '/') ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub5) &&
            currentPathURL(window.location.pathname) !== '/') ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub6) &&
            currentPathURL(window.location.pathname) !== '/') ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub7) &&
            currentPathURL(window.location.pathname) !== '/') ||
          (currentPathURL(window.location.pathname) === currentPathURL(submenu?.urlsub8) &&
            currentPathURL(window.location.pathname) !== '/')
        ) {
          dispatch(setOpenSubMenu(submenu?.parentMenu));
          dispatch(setMenuSelected(menuItems?.value));
          dispatch(setSubMenuSelected(submenu?.value));
        }
      });
      //TODO: Need to remove if there is no bug identify

      if (
        window.location.pathname === menuItems?.urlsub ||
        window.location.pathname === menuItems?.url ||
        window.location.pathname === menuItems?.urlsub1 ||
        (currentPathURL(window.location.pathname) === currentPathURL(menuItems?.urlsub1) &&
          currentPathURL(window.location.pathname) !== '/') ||
        (currentPathURL(window.location.pathname) === currentPathURL(menuItems?.urlsub2) &&
          currentPathURL(window.location.pathname) !== '/')
      ) {
        dispatch(setOpenSubMenu(menuItems?.parentMenu));
        dispatch(setMenuSelected(menuItems?.value));
        dispatch(setSubMenuSelected(menuItems?.value));
      }
    });
  };

  return (
    <aside className="mainsidebar">
      <Logo />
      <div className="scroller">
        <ul className="navlist">
          <div>
            {/* Mapping main menu items  */}
            {menuItems.map((menuItem, index) => (
              <MenuItem
                menuItem={menuItem}
                key={index}
                handleSubMenu={handleSubMenu}
                setOpenSubMenu={setOpenSubMenu}
              />
            ))}
          </div>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
