import {
  ADMIN_PROVIDERS_LISTING,
  ADMIN_FACILITIES_LISTING,
  ADMIN_ADD_FACILITIES,
  ADMIN_EDIT_FACILITIES,
  ADMIN_ICON_USERS_LISTING,
  ADMIN_CREATE_ICON_USERS,
  ADMIN_EDIT_ICON_USERS,
  ADMIN_ROLES_LISTING,
  ADMIN_CREATE_ROLES,
  ADMIN_EDIT_ROLES,
  ADMIN_VIEW_FACILITIES,
  ADMIN_VIEW_PROVIDERS,
  ADMIN_VIEW_DOCUMENTS,
  ADMIN_VIEW_CREDENTIALING,
  ADMIN_VIEW_PROFILE_HISTORY,
  ADMIN_VIEW_PROVIDER_FACILITIES,
  ADMIN_PAYRATES_LISITING,
  ADMIN_CREATE_PAYRATES,
  ADMIN_ADD_PAYRATES_IMPORT,
  ADMIN_VIEW_PROVIDER_ASSIGN_FACILITY,
  ADMIN_VIEW_PROVIDER_EDIT_FACILITY,
  ADMIN_VIEW_PROVIDER_VIEW_FACILITY,
  ADMIN_MALPRACTICE_LISITING,
  ADMIN_EXPENSES_LISTING,
  ADMIN_BONUS_LISITING,
  ADMIN_NOTIFICATIONS_LISTING,
  ADMIN_TIMESHEET_LISTING,
  ADMIN_ADD_TIMESHEET,
  ADMIN_EDIT_TIMESHEET,
  ADMIN_PAYMENTS_LISTING,
  ADMIN_VIEW_PAYMENT,
  ADMIN_VIEW_TIMESHEET,
  ADMIN_PAY_PERIODS_LISTING
} from './page-urls';

export const menuItemsAdmin = [
  {
    title: 'Providers',
    url: ADMIN_PROVIDERS_LISTING,
    icon: 'icon icon-mulpusers',
    value: 1,
    id: 'providers',
    submenu: [
      {
        title: 'Provider Listing',
        id: 'providers-listing',
        url: ADMIN_PROVIDERS_LISTING,
        urlsub1: ADMIN_VIEW_PROVIDERS,
        urlsub2: ADMIN_VIEW_DOCUMENTS,
        urlsub3: ADMIN_VIEW_CREDENTIALING,
        urlsub4: ADMIN_VIEW_PROFILE_HISTORY,
        urlsub5: ADMIN_VIEW_PROVIDER_FACILITIES,
        urlsub6: ADMIN_VIEW_PROVIDER_ASSIGN_FACILITY,
        urlsub7: ADMIN_VIEW_PROVIDER_EDIT_FACILITY,
        urlsub8: ADMIN_VIEW_PROVIDER_VIEW_FACILITY,
        icon: '',
        parentMenu: 'Providers',
        value: 1
      },
      {
        title: 'Provider Rates',
        id: 'provider-rates',
        url: ADMIN_PAYRATES_LISITING,
        urlsub: ADMIN_CREATE_PAYRATES,
        urlsub1: ADMIN_ADD_PAYRATES_IMPORT,
        icon: '',
        parentMenu: 'Providers',
        value: 2
      },
      {
        title: 'Malpractice Rates',
        id: 'malpractice-rates',
        url: ADMIN_MALPRACTICE_LISITING,
        icon: '',
        parentMenu: 'Providers',
        value: 3
      }
    ]
  },
  {
    title: 'Facilities',
    id: 'facilities',
    url: ADMIN_FACILITIES_LISTING,
    urlsub: ADMIN_ADD_FACILITIES,
    urlsub1: ADMIN_EDIT_FACILITIES,
    urlsub2: ADMIN_VIEW_FACILITIES,
    icon: 'icon icon-Group light-weight',
    value: 2
  },
  {
    title: 'ICON Users',
    id: 'icon-users',
    url: ADMIN_ICON_USERS_LISTING,
    icon: 'icon icon-mulpusers',
    value: 7,
    submenu: [
      {
        title: 'Users Listing',
        id: 'users-listing',
        url: ADMIN_ICON_USERS_LISTING,
        urlsub: ADMIN_CREATE_ICON_USERS,
        urlsub1: ADMIN_EDIT_ICON_USERS,
        icon: '',
        parentMenu: 'ICON Users',
        value: 7
      },
      {
        title: 'Roles',
        id: 'roles',
        url: ADMIN_ROLES_LISTING,
        urlsub: ADMIN_CREATE_ROLES,
        urlsub1: ADMIN_EDIT_ROLES,
        icon: '',
        parentMenu: 'ICON Users',
        value: 2
      }
    ]
  },
  {
    title: 'Expenses',
    id: 'expenses',
    url: ADMIN_EXPENSES_LISTING,
    icon: 'icon icon-Expense-Management light-weight',
    value: 3
  },
  {
    title: 'Bonuses',
    id: 'bonuses',
    url: ADMIN_BONUS_LISITING,
    icon: 'icon icon-bonus light-weight',
    value: 4
  },
  {
    title: 'Time Sheets',
    id: 'time-sheets',
    url: ADMIN_TIMESHEET_LISTING,
    urlsub: ADMIN_ADD_TIMESHEET,
    urlsub1: ADMIN_EDIT_TIMESHEET,
    urlsub2: ADMIN_VIEW_TIMESHEET,
    icon: 'icon icon-TimeSheets light-weight',
    value: 5
  },
  {
    title: 'Payments',
    id: 'payments',
    url: ADMIN_PAYMENTS_LISTING,
    urlsub: ADMIN_VIEW_PAYMENT,
    urlsub1: ADMIN_VIEW_PAYMENT,
    icon: 'icon icon-payments light-weight',
    value: 6
  },
  {
    title: 'Notifications',
    id: 'notifications',
    url: ADMIN_NOTIFICATIONS_LISTING,
    icon: 'icon icon-bellnoti',
    value: 1111
  },
  {
    title: 'Settings',
    id: 'settings',
    url: ADMIN_PAY_PERIODS_LISTING,
    icon: 'icon icon-Services',
    value: 8,
    submenu: [
      {
        title: 'Pay Periods',
        id: 'pay-periods',
        url: ADMIN_PAY_PERIODS_LISTING,
        icon: '',
        parentMenu: 'Settings',
        value: 1
      }
    ]
  }
];
