import _ from 'lodash';
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from 'services/interfaces/axios-methods';
import { getEndpoint } from 'helpers/utility-functions';

// Getting all permissions record service
async function rolePermissions(role) {
  try {
    const response = await getRequest(getEndpoint(role)?.GET_PERMISSIONS_API_URL);
    if (response?.data?.success) {
      return await response?.data?.data;
    }
  } catch (error) {
    return await error;
  }
}

// Getting Active User Permissions
async function userPermissions(role) {
  try {
    const response = await getRequest(getEndpoint(role)?.GET_USER_PERMISSIONS_API_URL);
    if (response?.data?.success) {
      return await response?.data?.data;
    }
  } catch (error) {
    return await error;
  }
}

// Create new role service
async function createRole(values, role) {
  try {
    const response = await postRequest(getEndpoint(role)?.CUD_ROLE_API_URL, values);
    return await response?.data;
  } catch (error) {
    return await error;
  }
}

// Update existing role service
async function updateRoleById(id, values, role) {
  try {
    const response = await putRequest(getEndpoint(role)?.CUD_ROLE_API_URL + `/${id}`, values);
    return await response?.data;
  } catch (error) {
    return await error;
  }
}

// Get single role record service
async function getRoleById(id, role) {
  try {
    const response = await getRequest(getEndpoint(role)?.CUD_ROLE_API_URL + `/${id}`);
    if (response?.data?.success) {
      return await response?.data?.data;
    }
  } catch (error) {
    return await error;
  }
}

// Delete exisiting role service
async function deleteRoleById(id, role) {
  try {
    const response = await deleteRequest(getEndpoint(role)?.CUD_ROLE_API_URL + `/${id}`);
    return await response;
  } catch (error) {
    return await error;
  }
}

// Getting all paginated roles record service
async function getRoles(offset, limit, values = {}, sorting, role) {
  // Remove empty search keys from search filter

  //pickBy Remove empty keys from values
  //If only sorting values then add in payload
  //If both filter and sorting values then add in payload
  const roleValue = Object.assign(
    {},
    _.pickBy(values),
    _.pickBy(sorting?.sortBy !== undefined && sorting?.sortDirection !== '' ? sorting : {})
  );

  try {
    const response = await postRequest(
      getEndpoint(role)?.GET_ROLE_API_URL + `?page=${offset}&pageSize=${limit}`,
      roleValue
    );
    return await response?.data;
  } catch (error) {
    return await error;
  }
}

// Getting all roles post request record service
async function getAllRoles(role) {
  try {
    const response = await postRequest(getEndpoint(role)?.GET_ROLE_API_URL);
    if (response?.data?.success) {
      return await response?.data?.data?.docs;
    }
  } catch (error) {
    return await error;
  }
}

// Getting all roles get request record service
async function getAllRolesGETRequest(role) {
  try {
    const response = await getRequest(getEndpoint(role)?.CUD_ROLE_API_URL);
    if (response?.data?.success) {
      return await response?.data?.data;
    }
  } catch (error) {
    return await error;
  }
}

export {
  createRole,
  getRoleById,
  updateRoleById,
  rolePermissions,
  getRoles,
  deleteRoleById,
  getAllRoles,
  userPermissions,
  getAllRolesGETRequest
};
