import { SET_SCOPES, GET_PERMISSIONS, EMPTY_PERMISSIONS } from 'redux/action-types';

const initialState = {
  SCOPES: {},
  userPermission: null
};
const permissionsActionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SCOPES:
      return {
        ...state,
        SCOPES: payload
      };
    case GET_PERMISSIONS:
      return {
        ...state,
        userPermission: payload && payload?.map((permission) => permission.value)
      };
    case EMPTY_PERMISSIONS:
      return {
        ...state,
        SCOPES: null,
        userPermission: null
      };
    default:
      return state;
  }
};
export default permissionsActionReducer;
