import { ROLES_CONSTANT } from 'helpers/constants';
import { useSelector } from 'react-redux';

//Create a custom hook for returning auth state after getting from redux store
const useUserData = () => {
  const { isLoggedIn, user } = useSelector((state) => state?.auth);
  const { profile, signedURL } = useSelector((state) => state?.profile);
  const { SCOPES } = useSelector((state) => state?.permissions);

  const role = user?.role;
  const token = user?.token;

  return {
    isLoggedIn,
    user,
    role,
    profile,
    signedURL,
    token,
    SCOPES,
    roleProvider: role === ROLES_CONSTANT?.provider,
    roleAdmin: role === ROLES_CONSTANT?.superAdmin,
    roleIconuser: role === ROLES_CONSTANT?.iconUser
  };
};

export default useUserData;
