import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROLES_CONSTANT, STATUS_CONSTANT } from 'helpers/constants';

const PrivateRouteProvider = ({ isLoggedIn, user }) => {
  return isLoggedIn &&
    user?.role === ROLES_CONSTANT.provider &&
    user?.profile?.status === STATUS_CONSTANT.confirmed ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRouteProvider;
