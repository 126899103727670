import { MENU_SELECTED, SUB_MENU_SELECTED, OPEN_SUB_MENU } from 'redux/action-types';

// Action for menu selection
export const setMenuSelected = (data) => (dispatch) => {
  dispatch({
    type: MENU_SELECTED,
    payload: { menuSelected: data }
  });
};

// Action for sub menu selection
export const setSubMenuSelected = (data) => (dispatch) => {
  dispatch({
    type: SUB_MENU_SELECTED,
    payload: { submenuSelected: data }
  });
};

// Action for opening sub menu
export const setOpenSubMenu = (data) => (dispatch) => {
  dispatch({
    type: OPEN_SUB_MENU,
    payload: { openSubMenu: data }
  });
};
