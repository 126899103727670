const LOGIN_API_URL = 'login';
const VERIFY_TOKEN = 'verify';
const RESEND = 'resend';
const UPLOAD = 'upload';
const MULTI_UPLOAD = 'upload-multiple';
const GET_CLIENTS_API_URL = 'list-clients';
const GET_VMS_NAMES_API_URL = 'list-vms';
const GET_JOB_TYPES_API_URL = 'list-job-types';
const GET_PAY_PERIODS_API_URL = 'list-pay-periods';
const VERIFY_TIMESHEET_BY_FACILITY_API_URL = 'verify-facility-access-timesheet';
const APPROVE_REJECT_TIMESHEET_BY_FACILITY_API_URL = 'facility-approve-timesheet';
const FORGOT_PASSWORD_API_URL = 'forgot-password';
const RESET_PASSWORD_API_URL = 'reset-password';
const GET_CREATED_BY_USERS = 'list-created-by-users';

// This is for admin when admin will access the provider profile routes
const adminProvider = {
  SIGN_UP_API_URL: 'super-admin/providers',
  GET_PROFILE: 'super-admin/providers/profile',
  BASIC_INFO: 'super-admin/providers/profile/basic-info',
  ADDRESS_AND_TIMEZONE: 'super-admin/providers/profile/address-and-timezone',
  CONTACT_INFORMATION: 'super-admin/providers/profile/contact-information',
  AREAS_OF_EXPERTISE: 'super-admin/providers/profile/areas-of-expertise',
  INCOP_STATUS: 'super-admin/providers/profile/incorporated-status',
  ICON_INFORMATION: 'super-admin/providers/profile/icon-information',
  EXPORT_TIMESHEET_DATA: 'super-admin/time-sheet/export'
};
const iconUser = {
  SIGN_UP_API_URL: 'icon-users/providers',
  GET_ROLE_API_URL: 'icon-users/roles/all',
  CUD_ROLE_API_URL: 'icon-users/roles',
  GET_ICONUSER_API_URL: 'icon-users/icon-users/all',
  CUD_ICONUSER_API_URL: 'icon-users/icon-users',
  GET_FACILITIES_API_URL: 'icon-users/facilities/all',
  CUD_FACILITIES_API_URL: 'icon-users/facilities',
  GET_PROVIDERS_API_URL: 'icon-users/providers/all',
  CUD_PROVIDERS_API_URL: 'icon-users/providers',
  GET_PERMISSIONS_API_URL: 'icon-users/permissions',
  GET_USER_PERMISSIONS_API_URL: 'icon-users/permissions/list',
  GET_ALL_COMPANIES_API_URL: 'icon-users/companies/all',
  GET_ALL_DEPARTMENTS_API_URL: 'icon-users/departments/all',
  CUD_FACILITY_ASSIGNMENT_API_URL: 'icon-users/provider-facility-assignments',
  GET_FACILITY_ASSIGNMENT_API_URL: 'icon-users/provider-facility-assignments/all',
  GET_FACILITY_CONTACTS: 'icon-users/time-sheet/list-facility-contacts',
  GET_ALL_PROVIDER_FACILITY_ASSIGNMENT_API_URL: 'icon-users/provider-facility-assignments/list-all',
  AREAS_OF_EXPERTISE: 'icon-users/providers/profile/areas-of-expertise',
  GET_PROFILE: 'icon-users/providers/profile',
  BASIC_INFO: 'icon-users/providers/profile/basic-info',
  ADDRESS_AND_TIMEZONE: 'icon-users/providers/profile/address-and-timezone',
  CONTACT_INFORMATION: 'icon-users/providers/profile/contact-information',
  INCOP_STATUS: 'icon-users/providers/profile/incorporated-status',
  ICON_INFORMATION: 'icon-users/providers/profile/icon-information',
  CUD_BONUSES_API_URL: 'icon-users/bonuses',
  GET_ALL_BONUSES_API_URL: 'icon-users/bonuses/all',
  APPROVE_BONUS_API_URL: 'icon-users/bonuses/approve',
  GET_ALL_BONUS_TYPES_API_URL: 'icon-users/bonus-types/all',
  EXPENSE_ASSIGNED_FACILITIES_API_URL: 'icon-users/expenses/assigned/facilities',
  CUD_MALPRACTICE_RATES_API_URL: 'icon-users/malpractice-rates',
  DELETE_MALPRACTICE_INFO: 'icon-users/malpractice-rates/malpractice-info',
  DELETE_STATE_MALPRACTICE: 'icon-users/malpractice-rates/state-rates',
  GET_SINGLE_MALPRACTICE_INFO_API_URL: 'icon-users/malpractice-rates/malpractice-info',
  GET_ALL_MALPRACTICE_INFO_API_URL: 'icon-users/malpractice-rates/malpractice-info/all',
  GET_SINGLE_MALPRACTICE_STATE_RATE_API_URL: 'icon-users/malpractice-rates/state-rates',
  GET_MALPRACTICE_STATE_RATES_API_URL: 'icon-users/malpractice-rates/state-rates/all',
  GET_MALPRACTICE_RATES_EXPORT_API_URL: 'icon-users/malpractice-rates/export',
  UPLOAD_MALPRACTICE_RATES_CSV_API_URL: 'icon-users/malpractice-rates/import',
  GETALL_EXPENSES_API_URL: 'icon-users/expenses/all',
  CUD_EXPENSES_API_URL: 'icon-users/expenses',
  APPROVE_EXPENSE_API_URL: 'icon-users/expenses/approve',
  EXPENSES_REPORT_DOWNLOAD_API_URL: 'icon-users/expenses/report/download',
  GET_INSURANCE_PROVIDERS: 'icon-users/malpractice-insurance-provider/all',
  GET_TIMESHEET_REMAINDERS_API_URL: 'icon-users/timesheet-remainders/all',
  CUD_TIMESHEET_REMAINDERS_API_URL: 'icon-users/timesheet-remainders',
  CUD_TIMESHEET_REMAINDERS_UPLOAD_API_URL: 'icon-users/timesheet-remainders/import',
  GET_FACILITY_ASSIGNED_PROVIDERS_BY_PERIOD:
    'icon-users/provider-facility-assignments/assigned/providers',
  GET_ALL_TIMESHEETS_API_URL: 'icon-users/time-sheet/all',
  GET_APPROVAL_BY_API_URL: 'icon-users/time-sheet/approved-by-users',
  DOWNLOAD_TIMESHEEET_EXAMPLE: 'icon-users/time-sheet/example',
  DOWNLOAD_TIMESHEET_PDF: 'icon-users/time-sheet/pdf',
  GET_CREATE_BY_API_URL: 'icon-users/time-sheet/created-by-users',
  GET_PROVIDER_ASSIGNED_FACILITIES: 'icon-users/provider-facility-assignments/active',
  ADD_TIMESHEET_API_URL: 'icon-users/time-sheet',
  GET_FACILITY_TIMESHEET: 'icon-users/time-sheet/get',
  VIEW_TIMESHEET_API_URL: 'icon-users/time-sheet',
  SUBMIT_FOR_APPROVAL_TIMESHEET_API_URL: 'icon-users/time-sheet/submit-for-approval',
  GET_SAMPLE_EXPORT_API_URL: 'icon-users/provider-facility-assignments/export',
  CUD_PAYRATE_API_URL: 'icon-users/pay-rate',
  GET_PAYRATES_API_URL: 'icon-users/pay-rate/all',
  UPLOAD_PAYRATESCSV_API_URL: 'icon-users/pay-rate/import',
  APPROVE_PAYRATES_API_URL: 'icon-users/pay-rate/approve',
  UPDATE_PASSWORD_API_URL: 'icon-users/update-password',
  DELETE_TIMESHEET: 'icon-users/time-sheet',
  EXPORT_TIMESHEET_DATA: 'icon-users/time-sheet/export',
  GET_ALL_PAYMENTS_API_URL: 'icon-users/payments/all',
  VIEW_PAYMENT_DETAILS_API_URL: 'icon-users/payments',
  RECALCULATE_PAYMENT_API_URL: 'icon-users/payments/recalculate',
  GET_PAYPERIODS_API_URL: 'icon-users/pay-periods/all',
  DAY_WISE_BIWEEKLY_PAYMENT_DOWNLOAD_API_URL: 'icon-users/payments/day-wise/export',
  BI_WEEKLY_PAYMENT_DOWNLOAD_API_URL: 'icon-users/payments/bi-weekly/export',
  APPROVE_REJECT_TIMESHEET: 'icon-users/time-sheet/approve-timesheet',
  UPLOAD_TIMESHEET_API_URL: 'icon-users/time-sheet/import'
};
// This is for admin when admin will access its own routes
const superAdmin = {
  GET_ROLE_API_URL: 'super-admin/roles/all',
  CUD_ROLE_API_URL: 'super-admin/roles',
  GET_ICONUSER_API_URL: 'super-admin/icon-users/all',
  CUD_ICONUSER_API_URL: 'super-admin/icon-users',
  GET_FACILITIES_API_URL: 'super-admin/facilities/all',
  CUD_FACILITIES_API_URL: 'super-admin/facilities',
  GET_PROVIDERS_API_URL: 'super-admin/providers/all',
  CUD_PROVIDERS_API_URL: 'super-admin/providers',
  GET_PERMISSIONS_API_URL: 'super-admin/permissions',
  GET_USER_PERMISSIONS_API_URL: 'super-admin/permissions/list',
  GET_ALL_COMPANIES_API_URL: 'super-admin/companies/all',
  GET_ALL_DEPARTMENTS_API_URL: 'super-admin/departments/all',
  CUD_FACILITY_ASSIGNMENT_API_URL: 'super-admin/provider-facility-assignments',
  GET_FACILITY_ASSIGNMENT_API_URL: 'super-admin/provider-facility-assignments/all',
  GET_FACILITY_CONTACTS: 'super-admin/time-sheet/list-facility-contacts',
  GET_ALL_PROVIDER_FACILITY_ASSIGNMENT_API_URL:
    'super-admin/provider-facility-assignments/list-all',
  GET_SAMPLE_EXPORT_API_URL: 'super-admin/provider-facility-assignments/export',
  CUD_PAYRATE_API_URL: 'super-admin/pay-rate',
  GET_PAYRATES_API_URL: 'super-admin/pay-rate/all',
  UPLOAD_PAYRATESCSV_API_URL: 'super-admin/pay-rate/import',
  APPROVE_PAYRATES_API_URL: 'super-admin/pay-rate/approve',
  GET_INSURANCE_PROVIDERS: 'super-admin/malpractice-insurance-provider/all',
  GET_DIVISIONS: 'super-admin/division/all',
  GET_PROCEDURE_TYPES: 'super-admin/procedure-type/all',
  CUD_MALPRACTICE_RATES_API_URL: 'super-admin/malpractice-rates',
  DELETE_MALPRACTICE_INFO: 'super-admin/malpractice-rates/malpractice-info',
  DELETE_STATE_MALPRACTICE: 'super-admin/malpractice-rates/state-rates',
  GET_SINGLE_MALPRACTICE_INFO_API_URL: 'super-admin/malpractice-rates/malpractice-info',
  GET_ALL_MALPRACTICE_INFO_API_URL: 'super-admin/malpractice-rates/malpractice-info/all',
  GET_SINGLE_MALPRACTICE_STATE_RATE_API_URL: 'super-admin/malpractice-rates/state-rates',
  GET_MALPRACTICE_STATE_RATES_API_URL: 'super-admin/malpractice-rates/state-rates/all',
  GET_MALPRACTICE_RATES_EXPORT_API_URL: 'super-admin/malpractice-rates/export',
  UPLOAD_MALPRACTICE_RATES_CSV_API_URL: 'super-admin/malpractice-rates/import',
  GETALL_EXPENSES_API_URL: 'super-admin/expenses/all',
  EXPENSE_ASSIGNED_FACILITIES_API_URL: 'super-admin/expenses/assigned/facilities',
  CUD_EXPENSES_API_URL: 'super-admin/expenses',
  APPROVE_EXPENSE_API_URL: 'super-admin/expenses/approve',
  EXPENSES_REPORT_DOWNLOAD_API_URL: 'super-admin/expenses/report/download',
  GET_ALL_BONUS_TYPES_API_URL: 'super-admin/bonus-types/all',
  CUD_BONUSES_API_URL: 'super-admin/bonuses',
  GET_ALL_BONUSES_API_URL: 'super-admin/bonuses/all',
  APPROVE_BONUS_API_URL: 'super-admin/bonuses/approve',
  GET_TIMESHEET_REMAINDERS_API_URL: 'super-admin/timesheet-remainders/all',
  CUD_TIMESHEET_REMAINDERS_API_URL: 'super-admin/timesheet-remainders',
  CUD_TIMESHEET_REMAINDERS_UPLOAD_API_URL: 'super-admin/timesheet-remainders/import',
  VIEW_TIMESHEET_API_URL: 'super-admin/time-sheet',
  GET_FACILITY_ASSIGNED_PROVIDERS_BY_PERIOD:
    'super-admin/provider-facility-assignments/assigned/providers',
  GET_ALL_TIMESHEETS_API_URL: 'super-admin/time-sheet/all',
  GET_APPROVAL_BY_API_URL: 'super-admin/time-sheet/approved-by-users',
  GET_CREATE_BY_API_URL: 'super-admin/time-sheet/created-by-users',
  // GET_PROVIDER_ASSIGNED_FACILITIES:
  //   'super-admin/provider-facility-assignments/provider-assigned-facility',
  GET_PROVIDER_ASSIGNED_FACILITIES: 'super-admin/provider-facility-assignments/active',
  ADD_TIMESHEET_API_URL: 'super-admin/time-sheet',
  GET_FACILITY_TIMESHEET: 'super-admin/time-sheet/get',
  DOWNLOAD_TIMESHEEET_EXAMPLE: 'super-admin/time-sheet/example',
  DOWNLOAD_TIMESHEET_PDF: 'super-admin/time-sheet/pdf',
  SUBMIT_FOR_APPROVAL_TIMESHEET_API_URL: 'super-admin/time-sheet/submit-for-approval',
  UPDATE_PASSWORD_API_URL: 'super-admin/update-password',
  APPROVE_REJECT_TIMESHEET: 'super-admin/time-sheet/approve-timesheet',
  DELETE_TIMESHEET: 'super-admin/time-sheet',
  GET_ALL_PAYMENTS_API_URL: 'super-admin/payments/all',
  VIEW_PAYMENT_DETAILS_API_URL: 'super-admin/payments',
  RECALCULATE_PAYMENT_API_URL: 'super-admin/payments/recalculate',
  GET_PAYPERIODS_API_URL: 'super-admin/pay-periods/all',
  DAY_WISE_BIWEEKLY_PAYMENT_DOWNLOAD_API_URL: 'super-admin/payments/day-wise/export',
  BI_WEEKLY_PAYMENT_DOWNLOAD_API_URL: 'super-admin/payments/bi-weekly/export',
  UPLOAD_TIMESHEET_API_URL: 'super-admin/time-sheet/import',
  ...adminProvider
};

const address = {
  GET_COUNTRIES_API_URL: 'list-countries',
  GET_CITIES_API_URL: 'list-cities',
  GET_STATES_API_URL: 'list-states',
  GET_TIMEZONES: 'list-timezones'
};

// This is for provider when provider will access its own profile routes
const provider = {
  SIGN_UP_API_URL: 'signup',
  GET_PROFILE: 'providers/profile',
  BASIC_INFO: 'providers/profile/basic-info',
  ADDRESS_AND_TIMEZONE: 'providers/profile/address-and-timezone',
  CONTACT_INFORMATION: 'providers/profile/contact-information',
  AREAS_OF_EXPERTISE: 'providers/profile/areas-of-expertise',
  INCOP_STATUS: 'providers/profile/incorporated-status',
  CUD_EXPENSES_API_URL: 'providers/expenses',
  GETALL_EXPENSES_API_URL: 'providers/expenses/all',
  EXPENSE_ASSIGNED_FACILITIES_API_URL: 'providers/expenses/assigned/facilities',
  GET_FACILITIES_TIMESHEET_API_URL: 'providers/provider-facility-assignments/active',
  ADD_TIMESHEET_API_URL: 'providers/time-sheet',
  GET_FACILITY_TIMESHEET: 'providers/time-sheet/get',
  GET_FACILITY_CONTACTS: 'providers/time-sheet/list-facility-contacts',
  GET_FACILITY_ASSIGNMENT_API_URL: 'providers/provider-facility-assignments/all',
  CUD_FACILITIES_API_URL: 'providers/provider-facility-assignments/facilities/all',
  CUD_FACILITY_ASSIGNMENT_API_URL: 'providers/provider-facility-assignments',
  GET_ALL_COMPANIES_API_URL: 'providers/provider-facility-assignments/companies/all',
  GET_ALL_TIMESHEETS_API_URL: 'providers/time-sheet/all',
  GET_ASSIGNED_FACILITIES_API_URL: 'providers/time-sheet/assigned-facilities',
  GET_APPROVAL_BY_API_URL: 'providers/time-sheet/approved-by-users',
  GET_CREATE_BY_API_URL: 'providers/time-sheet/created-by-users',
  VIEW_TIMESHEET_API_URL: 'providers/time-sheet',
  SUBMIT_FOR_APPROVAL_TIMESHEET_API_URL: 'providers/time-sheet/submit-for-approval',
  UPDATE_PASSWORD_API_URL: 'providers/update-password',
  DELETE_TIMESHEET: 'providers/time-sheet'
};

export {
  LOGIN_API_URL,
  VERIFY_TOKEN,
  RESEND,
  UPLOAD,
  superAdmin,
  iconUser,
  address,
  provider,
  adminProvider,
  MULTI_UPLOAD,
  GET_CLIENTS_API_URL,
  GET_VMS_NAMES_API_URL,
  GET_JOB_TYPES_API_URL,
  GET_PAY_PERIODS_API_URL,
  VERIFY_TIMESHEET_BY_FACILITY_API_URL,
  APPROVE_REJECT_TIMESHEET_BY_FACILITY_API_URL,
  FORGOT_PASSWORD_API_URL,
  RESET_PASSWORD_API_URL,
  GET_CREATED_BY_USERS
};
