import { combineReducers } from '@reduxjs/toolkit';
import auth from 'redux/reducers/auth/auth';
import profile from './profile/profile';
import loading from 'redux/reducers/loading/loading';
import sidebarMenu from './sidebar-menu/sidebarMenu';
import permissions from 'redux/reducers/permissions/permissions';
import searchFilters from './search-filters/searchFilters';

export default combineReducers({
  auth,
  loading,
  profile,
  sidebarMenu,
  permissions,
  searchFilters
});
