import { MENU_SELECTED, OPEN_SUB_MENU, SUB_MENU_SELECTED, DEFAULT_MENU } from 'redux/action-types';
// Initial state for sideabar menu
// Initially the user dropdown menu is open and inside provider submenu is selected
const initialState = {
  menuSelected: 1,
  submenuSelected: 1,
  openSubMenu: 'Providers'
};

const sidebarMenuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // For dropdown menus
    case MENU_SELECTED:
      return {
        ...state,
        menuSelected: payload?.menuSelected
      };
    // For sub menus inside the dropdown menu
    case SUB_MENU_SELECTED:
      return {
        ...state,
        submenuSelected: payload?.submenuSelected
      };
    // For opening the sub menus inside the dropdown menu
    case OPEN_SUB_MENU:
      return {
        ...state,
        openSubMenu: payload?.openSubMenu
      };
    case DEFAULT_MENU:
      return initialState;
    default:
      return state;
  }
};
export default sidebarMenuReducer;
